<template>
	<div>
		<v-footer
			id="home-footer"
			color="grey darken-4"
			dark
			min-height="72"
		>
			<v-container>
				<v-row>
					<v-col
						cols="12"
						md="6"
					>
						<div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
							<div class="text-sm">Datacom Merchant Services LLC is a Registered MSP/ISO of Elavon, Inc.</div>
							<div class="text-sm">a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN</div>
							
						</div>
					</v-col>

					<v-col
						class="text-center text-md-right"
						cols="12"
						md="6"
					>
						<div>
							Copyright &copy; 2020 Datacom Business Systems, LLC
						</div>
						<div class="row justify-end">
								<span>
									<v-btn text
										@click="openModal = true">
										Privacy Policy
									</v-btn>
								</span>
								<span>
									<v-btn text
										@click="openRefundModal = true">
										Refund Policy
									</v-btn>
								</span>
							
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>

		<!-- Privacy Modal -->
		<modalComponent 
			:openModal="openModal"
			@closeModal="closeModal">
			<template slot="title">
				<div class="headline-3">
					Privacy Policy
				</div>
			</template>
			<template slot="body">
				<div class="row">
					<div class="col-12 text-left">
						<p>
							This privacy policy discloses the privacy practices for Datacom Business Systems. This privacy policy applies solely to information collected by this web site. It will notify you of the following:
						</p>
						<ul>
							<li>
								What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.
							</li>
							<li>
								What choices are available to you regarding the use of your data.
							</li>
							<li>
								The security procedures in place to protect the misuse of your information.
							</li>
							<li>
								How you can correct any inaccuracies in the information.
							</li>
						</ul>
						<br>
							<hr />
						<br>
						<div class="title">
							Information collection, use, and sharing
						</div>
						<ul>
							<li>
								We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
							</li>
							<li>
								We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to process a credit card.
							</li>
							<li>
								Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
							</li>
						</ul>
						<br>
							<hr />
						<br>
						<div class="title">
							Your access to and control over information
						</div>
						<p>
							You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:
						</p>
						<ul>
							<li>
								See what data we have about you, if any.
							</li>
							<li>
								Change/correct any data we have about you.
							</li>
							<li>
								Have us delete any data we have about you.
							</li>
							<li>
								Express any concern you have about our use of your data.
							</li>
						</ul>
						<br>
							<hr />
						<br>
						<div class="title">
							Security
						</div>
						<p>
							We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
						</p>
						<P>
							Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for "https" at the beginning of the address of the web page.
						</P>
						<P>
							While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
						</P>
						<div class="title">
							Credit card information
						</div>
						<p>
							We do not store your credit card information directly. It is passed through to a third party credit card processing merchant using 128-bit SSL encryption. We only keep your credit card's brand, last 4 digits and expiration date to assist and remind you of any billing related issues.
						</p>

						<br>
							<hr />
						<br>
						<div class="title">
							Cookies
						</div>
						<p>
							We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in with a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.
						</p>
						<P>
							If you do not want to be tracked by Google Analytics you may opt-out using Google's official opt-out browser add-on .
						</P>
						<br>
							<hr />
						<br>
						
						<div class="title">
							Updates
						</div>
						<p>
							Our privacy policy may change from time to time and all updates will be posted on this page.
						</p>
						<P>
							If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 855-699-3282 or via email.
						</P>
						<div class="text-sm">
							Last updated January 2021 
						</div>
						<br>
							<hr />
						<br>

					</div>
				</div>
			</template>
			<template slot="action-buttons">
				<div class="row justify-end mt-3">
					<div class="col-3">
						<v-btn 
						color="primary"
						@click="openModal = false">
						Close
					</v-btn>
					</div>
				</div>
			</template>
		</modalComponent>
		<!-- Refund Modal -->
			<template>
			<div>

				<v-overlay 
					:value="openRefundModal">
					<v-sheet 
						id="v-sheet"
						light
						:min-width="600"
						elevation="2"
						:width="windowWidth"
						height="80vh"
						class="text-center mx-auto v-sheet">
						
						<div class="row bg-primary">
							<div class="col-9 text-left flex items-center px-5">
								<div class="headline-5 text-white">
									<div class="headline-3">
										Privacy Policy
									</div>
								</div>
							</div>
							<div class="col-3 h-16 flex items-center justify-end pr-5">
								<v-btn
									icon
									color="white"
									@click="openRefundModal = false"
								>
									<v-icon size="60">mdi-close-circle-outline</v-icon>
								</v-btn>
							</div>
						</div>

						<div class="row">
							<div 	
								class="p-4 w-full"
								width="100%">
								<DxScrollView
									show-scrollbar="always"
									class="customHeight"
									width="100%">
									
									<p>
										Please call your merchant for refund policies and instructions.
									</p>
									<p>
										Please call us if you require assistance.
									</p>
									
								</DxScrollView>
							</div>
						</div>


						<div class="row border-t-4 border-gray-400">
							<div class="row justify-end mt-3">
								<div class="col-3">
									<v-btn 
									color="primary"
									@click="openRefundModal = false">
									Close
								</v-btn>
								</div>
							</div>
						</div>
							

					</v-sheet>
					
				</v-overlay>
				
			</div>
		</template>
	</div>
</template>

<script>
import modalComponent from "@/components/popups/modal-component"

// Devexpress
import { DxScrollView } from 'devextreme-vue/scroll-view'

  export default {
		name: 'SlimlineFooter',
		components: {
			modalComponent,
			DxScrollView
		},
    data: () => ({
			//Main Data
			openModal: false,
			openRefundModal: false,

			//modal Data
			windowWidth: 0,

			privacyPolicyText: "sdavdsfsbsfbvfd"
		}),
		methods: {
			closeModal(evt) {
				this.openModal = evt
			},
			resizeWindow() {
				this.windowWidth = window.innerWidth *.8
			},
		},
		mounted () {
			this.windowWidth = window.innerWidth *.8
			window.addEventListener('resize', this.resizeWindow)
		},
		beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener('resize', this.resizeWindow)
		}
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
  .toolbar-items
    max-width:100px
  .customHeight
    height: calc(71vh - 80px)
</style>
